.qr-reader {
  width: 100%;
  height: 500px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 1);
}

.qr-reader video {
  width: 500px;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-box.scan-region-highlight {
  height: 0px !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(0);
}

.qr-video {
  object-fit: cover;
  border: 0 solid rgba(0, 0, 0, 1);
  border-radius: 0;
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
    height: 76vw;
  }

  .qr-reader .qr-frame {
    transform: translateX(-50%) translateY(-10%);
  }
}